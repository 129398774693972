import "./App.css";
import Card from "@mui/material/Card";
import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import Lottie from "lottie-react";
import loadingSplashAnimation from "./loadingSplash.json";
import notFound from "./404.json";
import logo from "./logo.png";
import appstore from "./DownloadAppStore.png";
import playstore from "./DownloadPlayStore.png";
import { Link } from "react-router-dom";
import profilePicDefault from "./profilePicDefault.png";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Snackbar, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useSearchParams } from "react-router-dom";

const SERVER_API_ADDRESS = "https://api.techcards.in/";
const LOCAL_API_ADDRESS = "http://127.0.0.1:8000/";
const SERVER_SOCKET_ADDRESS = "wss://api.techcards.in/";
const LOCAL_SOCKET_ADDRESS = "ws://127.0.0.1:8000/";
const PRODUCTION = true;

const images = require.context("./icons", true);
var url = window.location.href.split("://")[1].split("/");
const profile_tapped_link = url[1];
const profile_tapped_uid = url[2];
const profile_tapped = url[1];
const profile_tapper = url[3];
const profile_tapper_uid = url[4];

console.log(profile_tapped_link);
console.log(profile_tapped_uid);
console.log(profile_tapper);

function Profile() {
  const [isLoading, setIsLoading] = useState(true);
  const [profileLink, setProfileLink] = useState("");
  const [fullName, setFullName] = useState("");
  const [orgname, setOrgname] = useState("");
  const [designation, setDesignation] = useState("");
  const [imageLink, setImageLink] = useState("");
  const [imageLink2, setImageLink2] = useState("");
  const [socialLinks, setSocialLinks] = useState([]);
  const [found, setFound] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [alert, setAlert] = useState(false);
  const [userImage, setUserImage] = useState(false);
  const [businessLogo, setBusinessLogo] = useState(false);
  const [userCover, setUserCover] = useState(false);
  const [coverError, setCoverError] = useState(false);
  const [profilePicError, setProfilePicError] = useState(false);
  const [searchParams] = useSearchParams();
  //http://localhost:3000/review/?uid=aomhcw&title=Pania&url=https://g.page/r/CaAg76W1jnwTEAE/review
  const type = searchParams.get("uid");
  const c = searchParams.get("title");
  const cc = searchParams.get("url");

  function getData() {
    if (window.location.href.split("://")[1].split("/").length == 3) {
      axios
        .get(
          PRODUCTION
            ? SERVER_API_ADDRESS +
                "profile_link/" +
                profile_tapped_link +
                "/" +
                profile_tapped_uid
            : LOCAL_API_ADDRESS +
                "profile_link/" +
                profile_tapped_link +
                "/" +
                profile_tapped_uid
        )
        .then((res) => {
          setProfileLink(res.data.profile_link);
          setFullName(res.data.full_name);
          setOrgname(res.data.orgname);
          setDesignation(res.data.designation);
          setSocialLinks(res.data.social_links);
          setUserImage(res.data.user_image);
          setBusinessLogo(res.data.business_logo);
          setUserCover(res.data.user_cover);
          setFound(true);
          setIsLoading(false);
        })
        .catch((err) => {
          setFound(false);
          setIsLoading(false);
        });
    } else {
      axios
        .get(
          PRODUCTION
            ? SERVER_API_ADDRESS +
                "profile/" +
                profile_tapped +
                "/" +
                profile_tapped_uid +
                "/" +
                profile_tapper +
                "/" +
                profile_tapper_uid
            : LOCAL_API_ADDRESS +
                "profile/" +
                profile_tapped +
                "/" +
                profile_tapped_uid +
                "/" +
                profile_tapper +
                "/" +
                profile_tapper_uid
        )
        .then((res) => {
          setProfileLink(res.data.profile_link);
          setFullName(res.data.full_name);
          setOrgname(res.data.orgname);
          setDesignation(res.data.designation);
          setSocialLinks(res.data.social_links);
          setUserImage(res.data.user_image);
          setBusinessLogo(res.data.business_logo);
          setUserCover(res.data.user_cover);
          setFound(true);
          setIsLoading(false);
        })
        .catch((err) => {
          setFound(false);
          setIsLoading(false);
        });
    }
  }

  function launchURL(link, index) {
    window.open(link, "_blank");
  }

  function engage(index) {
    axios.post(
      PRODUCTION ? SERVER_API_ADDRESS + "engage" : LOCAL_API_ADDRESS + "engage",
      JSON.stringify({
        profile_link: profile_tapped_link,
        uid: profile_tapped_uid,
        index: index,
      })
    );
  }

  function getIcon(uda) {
    try {
      return (
        <Fragment>
          {" "}
          {uda["icon"].length == 0 ? (
            <img
              src={images("./" + uda["name"] + ".png")}
              style={{ height: "100%" }}
            />
          ) : (
            <img
              src={
                PRODUCTION
                  ? `${SERVER_API_ADDRESS}media/${uda["icon"]}`
                  : `${LOCAL_API_ADDRESS}media/${uda["icon"]}`
              }
              style={{ height: "100%" }}
            />
          )}
        </Fragment>
      );
    } catch (e) {
      return (
        <Fragment>
          {uda["icon"].length == 0 ? (
            <div></div>
          ) : (
            <img
              src={
                PRODUCTION
                  ? `${SERVER_API_ADDRESS}media/${uda["icon"]}`
                  : `${LOCAL_API_ADDRESS}media/${uda["icon"]}`
              }
              style={{ height: "100%" }}
            />
          )}
        </Fragment>
      );
    }
  }

  useEffect(() => {
    getData();
  }, []);

  if (isLoading) {
    return (
      <div className="App">
        <div
          style={{
            height: "100vh",
            width: "98vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Lottie
            style={{ height: "300px", width: "300px" }}
            animationData={loadingSplashAnimation}
            loop={true}
          />
        </div>
      </div>
    );
  } else {
    if (found) {
      return (
        <div className="App">
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openImage}
            onClose={() => setOpenImage(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openImage}>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  backgroundColor: "#121212",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "85%",
                  borderRadius: "6px",
                  padding: "15px",
                }}
              >
                <div style={{ marginTop: "-25px" }}>
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <IconButton size="small">
                      <CloseIcon
                        onClick={() => setOpenImage(false)}
                        style={{ color: "white", fontSize: "25px" }}
                      />
                    </IconButton>
                  </div>
                </div>
                <div
                  onClick={() => setOpenImage(false)}
                  style={{ display: "flex", overflow: "scroll" }}
                >
                  <img
                    onError={() => {}}
                    style={{
                      marginRight: "25px",
                      objectFit: "cover",
                      height: "75%",
                      width: "75%",
                    }}
                    src={
                      PRODUCTION
                        ? `${SERVER_API_ADDRESS}media/${imageLink}`
                        : `${LOCAL_API_ADDRESS}media/${imageLink}`
                    }
                  />

                  <img
                    onError={() => {}}
                    style={{
                      objectFit: "cover",
                      height: "75%",
                      width: "75%",
                    }}
                    src={
                      PRODUCTION
                        ? `${SERVER_API_ADDRESS}media/${imageLink2}`
                        : `${LOCAL_API_ADDRESS}media/${imageLink2}`
                    }
                  />
                </div>
              </div>
            </Fade>
          </Modal>
          <div
            style={{
              height: "100%",
              width: "98vw",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <a
              href={"https://www.techcards.in"}
              style={{
                padding: "3px 10px 3px 10px",
                margin: "10px 10px 3px 10px",
                backgroundColor: "rgba(0,0,0,0.6)",
                fontSize: "20px",
                fontWeight: "bold",
                textDecoration: "none",
                color: "#22CE83",
                position: "fixed",
                top: 0,
                left: 0,
              }}
            >
              Visit Site
            </a>
            <a
              href={
                "https://api.techcards.in/" +
                profile_tapped_link +
                "/" +
                profile_tapped_uid
              }
              style={{
                padding: "3px 10px 3px 10px",
                margin: "10px 10px 3px 10px",
                backgroundColor: "rgba(0,0,0,0.6)",
                fontSize: "20px",
                fontWeight: "bold",
                textDecoration: "none",
                color: "#22CE83",
                position: "fixed",
                top: 0,
                right: 0,
              }}
            >
              Open in App
            </a>
            <div
              elevation={13}
              style={{
                height: "25vh",
                width: "98vw",
                backgroundColor: "black",
              }}
            >
              {coverError ? (
                <span></span>
              ) : (
                <img
                  onError={() => {
                    setCoverError(true);
                  }}
                  style={{
                    objectFit: "cover",
                    height: "25vh",
                    width: "98vw",
                  }}
                  src={
                    PRODUCTION
                      ? `${SERVER_API_ADDRESS}media/${userCover}`
                      : `${LOCAL_API_ADDRESS}media/${userCover}`
                  }
                />
              )}
            </div>
            <div
              style={{
                marginTop: "-10vh",
                borderRadius: "50%",
                height: "150px",
                width: "150px",
                backgroundColor: "#22CE83",
              }}
            >
              {profilePicError ? (
                <img
                  style={{
                    objectFit: "cover",
                    borderRadius: "50%",
                    height: "100%",
                    width: "100%",
                  }}
                  src={profilePicDefault}
                />
              ) : (
                <img
                  onError={() => {
                    setProfilePicError(true);
                  }}
                  style={{
                    objectFit: "cover",
                    borderRadius: "50%",
                    height: "100%",
                    width: "100%",
                  }}
                  src={
                    PRODUCTION
                      ? `${SERVER_API_ADDRESS}media/${userImage}`
                      : `${LOCAL_API_ADDRESS}media/${userImage}`
                  }
                />
              )}
            </div>

            <div
              style={{
                marginTop: "25px",
                fontSize: "30px",
                fontWeight: "bold",
                color: "white",
              }}
            >
              {fullName}
            </div>
            <div
              style={{
                fontSize: "22px",
                fontWeight: "normal",
                color: "white",
              }}
            >
              {designation}
            </div>
            <div
              style={{
                fontSize: "22px",
                fontWeight: "bold",
                color: "white",
                marginBottom: "35px",
              }}
            >
              {orgname}
            </div>

            <Grid container spacing={0} style={{ width: "100%" }}>
              <Grid item sm={1} md={2} lg={3} style={{ width: "95%" }}></Grid>
              <Grid item sm={10} md={8} lg={6} style={{ width: "95%" }}>
                <div>
                  {socialLinks.map((uda, index) => (
                    <div
                      style={{
                        height: "60px",
                        backgroundColor: "#121212",
                        padding: "15px",
                        color: "#22CE83",
                        marginBottom: "15px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        onClick={() => {
                          engage(index);
                          if (uda["name"] == "image" || uda["name"] == "menu") {
                            setImageLink(JSON.parse(uda["data"])["images"][0]);
                            setImageLink2(JSON.parse(uda["data"])["images"][1]);
                            setOpenImage(true);
                          } else if (uda["name"] == "pdf") {
                            launchURL(
                              "https://api.techcards.in/media/" +
                                JSON.parse(uda["data"])["file"],
                              index
                            );
                          } else if (
                            uda["name"] == "upi" ||
                            uda["name"] == "google-pay" ||
                            uda["name"] == "paytm" ||
                            uda["name"] == "phone-pe"
                          ) {
                            launchURL(
                              "upi://pay?pa=" +
                                uda["data"] +
                                "&pn=" +
                                fullName +
                                "&cu=INR",
                              index
                            );
                          } else {
                            if (uda["open"].length == 0) {
                              if (
                                uda["data"].includes("http") ||
                                uda["data"].includes("https")
                              ) {
                                launchURL(uda["data"]);
                              } else {
                                launchURL("https://" + uda["data"]);
                              }
                            } else {
                              if (
                                uda["open"].includes("http") ||
                                uda["open"].includes("https")
                              ) {
                                launchURL(uda["data"]);
                              } else {
                                launchURL(uda["open"] + uda["data"]);
                              }
                            }
                          }
                        }}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        {getIcon(uda)}

                        <div style={{ textAlign: "left", marginLeft: "20px" }}>
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: "bold",
                            }}
                          >
                            {uda["uname"].length == 0
                              ? uda["name"].charAt(0).toUpperCase() +
                                uda["name"].slice(1)
                              : uda["uname"]}
                          </div>
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: "normal",
                              color: "white",
                            }}
                          >
                            {uda["name"] == "image" ||
                            uda["name"] == "menu" ||
                            uda["name"] == "pdf"
                              ? JSON.parse(uda["data"])["data"]
                              : uda["data"].length > 18
                              ? uda["data"].slice(0, 18) + "..."
                              : uda["data"]}
                          </div>
                        </div>
                      </div>
                      <Button
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          engage(index);
                          navigator.clipboard.writeText(uda["data"]);
                          setAlert(true);
                        }}
                      >
                        <ContentCopyIcon
                          style={{ fontSize: "30px", color: "white" }}
                        />
                      </Button>
                    </div>
                  ))}
                </div>
              </Grid>
              <Grid item sm={1} md={2} lg={3} style={{ width: "95%" }}></Grid>
            </Grid>
            <div
              style={{
                padding: "3px 10px 3px 10px",
                backgroundColor: "rgba(0,0,0,0.6)",
                fontSize: "20px",
                fontWeight: "bold",
                textDecoration: "none",
                color: "#22CE83",
                marginTop: "25px",
              }}
            >
              Download
            </div>
            <div
              style={{
                marginTop: "-25px",
                zIndex: "-1",
                marginBottom: "25px",
                width: "98%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "120px",
                backgroundColor: "#000000",
              }}
            >
              <img
                style={{
                  objectFit: "cover",
                  height: "60px",
                }}
                src={logo}
              />
            </div>
            <a
              target="_blank"
              href="https://apps.apple.com/fi/app/techcards/id6479240275"
              style={{
                paddingLeft: "5px",
                paddingRight: "5px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
                backgroundColor: "#000000",
                marginTop: "-45px",
              }}
              rel="noreferrer"
            >
              <img
                style={{
                  objectFit: "cover",
                  height: "60px",
                }}
                src={appstore}
              />
            </a>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=in.techcards.api"
              style={{
                paddingLeft: "5px",
                paddingRight: "5px",
                marginBottom: "25px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
                backgroundColor: "#000000",
                marginTop: "-15px",
              }}
              rel="noreferrer"
            >
              <img
                style={{
                  objectFit: "cover",
                  height: "60px",
                }}
                src={playstore}
              />
            </a>
            {alert ? (
              <Snackbar
                open={alert}
                autoHideDuration={3000}
                onClose={() => setAlert(false)}
                message="Content copied to Clipboard."
              />
            ) : (
              <Fragment></Fragment>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="App">
          <div
            style={{
              height: "100vh",
              width: "98vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#22CE83",
            }}
          >
            <Lottie
              style={{ height: "300px", width: "300px" }}
              animationData={notFound}
              loop={true}
            />
          </div>
        </div>
      );
    }
  }
}

export default Profile;
