import React from "react";
import { Router, Route, Routes, useNavigate } from "react-router-dom";
import Review from "./Review";
import Profile from "./Profile";

function App() {
  return (
    <Routes>
      <Route exact path="/review" element={<Review />} />
      <Route exact path="*" element={<Profile />} />
    </Routes>
  );
}

export default App;
